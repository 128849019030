<template>
  <v-card
    elevation="10"
    class="mx-2"
    height="590"
  >
    <v-toolbar
      dense
      primary-title
      color="amber"
    >
      <v-toolbar-title class="white--text">
        Mis Tareas
      </v-toolbar-title>

      <v-spacer />
      <v-btn
        text
        class="white--text"
        dark
        :to="{name: 'addorden'}"
      >
        Nueva
        <v-icon color="white">
          mdi-clipboard
        </v-icon>
      </v-btn>
    </v-toolbar>
    
    <!-- {{misordenes}} -->
    <v-list
      class="overflow-y-auto"
      height="540"
      two-line
    >
      <v-list-item-group
        v-model="selected"
        active-class="blue--text"
      >
        <template v-for="(item, index) in misordenes">
          <v-list-item :key="item.idcrm">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.titulo" />

                <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.obs"
                />

                <v-list-item-subtitle v-text="item.fecha" />
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="item.hrcreada" />

                <v-icon
                  v-if="!active"
                  color="grey lighten-1"
                >
                  mdi-star-outline
                </v-icon>

                <v-icon
                  v-else
                  color="yellow darken-3"
                >
                  mdi-star
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  data () {
    return {
      search: '',
      selected: [2],
      items: [],
      // selected: [1],
      misordenes: [],
      headers: [

        { text: 'Id de la orden', value: 'idorden' },
        { text: 'Urgencia', value: 'prioridad' },
        { text: 'Modulo', value: 'modulo' },
        { text: 'Titulo', value: 'titulo' },
        { text: 'Problema', value: 'obs' },
        { text: 'Fecha', align: 'start', value: 'substr(fecha,0,10)'},
        { text: 'Hr', value: 'obs' },
          
      ],
    }
  },

  computed: {
    ...mapGetters('login',['getdatosUsuario'])
  },

  created(){
    this.consultar()
  },

  
  methods: {
      
    consultar(){
      // limpiar
      this.misordenes= []
      // console.log(this.getdatosUsuario)

      let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)

      this.$http.get("v2/misordenes/" + id).then(response=>{
        if (response.data.error===null){
          // console.log(response)
          this.misordenes = response.data.result

          response.data.result.forEach( element => {
            element.fecha = element.fecha.substr(0,10)
          })

        }
          
      }).catch(error=>{
        console.log(error)
      })
    },
      
    ver(item){
      console.log("orden", item.idorden)
      this.verOrden(item.idordenes)
    },
  }
}

</script>


